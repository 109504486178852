<template lang="pug">
CanvasModal.successful-subscription(
  :isShow="isShow"
  isGrey=true
  @closeOverlay="closeModal"
  :hideCloseButton="hideCloseButton"
  :isFillHeight="smartCreate"
)
  img.successful-subscription__image(src="@/assets/images/upgrade/successful-upgrade.png")
  h2.successful-subscription__title(
    v-html="subscriptionTitle"
  )
  img.successful-subscription__mobileimg(
    v-if="isMobile"
    src="@/assets/images/smart-create/mobile-offeo.gif"
  )
  .successful-subscription__text(
    v-html="subscriptionText"
  )
  BaseButton.btn-close-successful-subscription(
    v-if="!hideCloseButton"
    isText=true
    @click="closeModal"
  ) Close window
</template>

<script>
import { mapMutations } from 'vuex';
import { isMobile } from '@/assets/scripts/utilities';

export default {
  name: 'SuccessfulSubscription',
  data() {
    return {
      isShow: true,
      isMobile: isMobile(),
    };
  },
  props: {
    smartCreate: { type: Boolean, default: false },
  },
  computed: {
    hideCloseButton() {
      return this.isMobile && this.smartCreate;
    },
    subscriptionTitle() {
      return this.isMobile ? 'Payment successful<br><span class="t--black">You\'re almost done!<span>' : 'Your subscription is successful';
    },
    subscriptionText() {
      if (this.isMobile) {
        return `
          <p>Open OFFEO’s email from your computer and continue your video creation </p>
        `;
      }
      return `
        <p>All features are now unlocked. Thank you for subscribing to OFFEO.</p>
        <p>An automated payment receipt will be sent to your registered email. Let’s start rocking the world!</p>
      `;
    },
  },
  methods: {
    ...mapMutations([
      'setShowSuccessfulSubscription',
    ]),
    closeModal() {
      this.setShowSuccessfulSubscription(false);
    },
  },
};
</script>

<style lang="scss">
.successful-subscription {
  img {
    max-width: 100%;
  }

  .smart-create & {
    @include mobile() {
      .canvas-modal__content {
        padding-top: 54px;
        max-height: 100%;

        .content {
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }
  }
}

.successful-subscription__title {
  color: $green;
  font-size: 2.25em;
  font-weight: bold;

  @include mobile() {
    font-size: 1.875em;
    margin: 0.5em 0;

    .t--black {
      color: $darkGrey;
    }
  };
}

.successful-subscription__text {
  font-size: 1.125em;

  p {
    margin: 10px 0;
  }

  @include mobile() {
    font-size: 1em;
  };
}

.successful-subscription__mobileimg {
  width: 150px;
  display: block;
  margin: 0 auto;
}

.btn-close-successful-subscription {
  color: $salmon;
  text-decoration: underline;
  z-index: 2;
  margin-top: 20px;

  &:hover,
  &:focus {
    color: $red;
  }
}
</style>
